<template>
    <TermStages></TermStages>
</template>

<script>
    import TermStages from "@/views/administration/management/term_stage/components/term_stages";
    export default {
        name: "index",
        components: {TermStages}
    }
</script>

<style scoped>

</style>